// app/javascript/controllers/toggle_controller.js
import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["content", "icon", "container", "sidebar"]

    toggle() {
        this.sidebarTarget.classList.toggle("hidden")
        this.containerTarget.classList.toggle("grid-cols-[1fr,350px]")
        this.containerTarget.classList.toggle("grid-cols-[1fr,0px]")
        this.iconTarget.textContent = this.sidebarTarget.classList.contains("hidden") ? "◀" : "▼"
    }
}